import React, { Component } from "react";
//import { Switch, Route } from "react-router";
import { withRouter } from "react-router-dom";
import Content from "../Content/index";

class App extends Component {

  render() {
    return (
      <>
        <Content {...this.props} />
      </>
    );
  }
}
export default withRouter(App);