export const drips = [
  {
    "id": "sedation",
    "header": "Sedation",
      "drugs": [
        { "id": "propofol", "name": "Propofol" },
        { "id": "versed", "name": "Versed" },
        { "id": "precedex", "name": "Precedex" },
        { "id": "ketamine", "name": "Ketamine" },
        { "id": "ativan", "name": "Ativan" },
        { "id": "fentanyl", "name": "Fentanyl" },
        { "id": "dilaudid", "name": "Dilaudid" },
        { "id": "morphine", "name": "Morphine" },
    ]
  },
  {
    "id": "inopressors",
    "header": "Inotropes/Pressors",
    "drugs": [
      { "id": "dobutamine", "name": "Dobutamine" },
      { "id": "dopamine", "name": "Dopamine" },
      { "id": "milrinone", "name": "Milrinone" },
      { "id": "levophed", "name": "Levophed" },
      { "id": "phenylephrine", "name": "Phenylephrine" },
      { "id": "neo", "name": "Neo" },
      { "id": "vasopressin", "name": "Vasopressin" },
      { "id": "epinephrine", "name": "Epinephrine" },
    ]
  },
  {
    "id": "cardiac",
    "header": "Cardiac",
    "drugs": [
      { "id": "amiodarone", "name": "Amiodarone" },
      { "id": "diltiazem", "name": "Diltiazem" },
      { "id": "esmolol", "name": "Esmolol" },
      { "id": "labetalol", "name": "Labetalol" },
      { "id": "nicardipine", "name": "Nicardipine" },
      { "id": "ntg", "name": "Nitroglycerin" },
      { "id": "nipride", "name": "Nipride" },
      { "id": "isuprel", "name": "Isuprel" },
    ]
  },
  {
    "id": "anticoag",
    "header": "Anticoag's",
    "drugs": [
      { "id": "heparin", "name": "Heparin" },
      { "id": "bivalirudin", "name": "Bivalirudin" },
      { "id": "argatroban", "name": "Argatroban" },
    ]
  },
  {
    "id": "metabolic",
    "header": "Metabolic",
    "drugs": [
      { "id": "insulin", "name": "Insulin" },
      { "id": "bicarb", "name": "Sodium Bicarb" },
      { "id": "hco3", "name": "HCO3" },
      { "id": "lasix", "name": "Lasix" },
      { "id": "protonix", "name": "Protonix" },
      { "id": "octreotide", "name": "Octreotide" },
      { "id": "narcan", "name": "Narcan" },
      { "id": "lidocaine", "name": "Lidocaine" },
      { "id": "veletri", "name": "Veletri" },
    ]
  },
  {
    "id": "paralytics",
    "header": "Paralytics",
    "drugs": [
      { "id": "nimbex", "name": "Nimbex" },
      { "id": "vecuronium", "name": "Vecuronium" },
      { "id": "rocuronium", "name": "Rocuronium" },
    ]
  },
  {
    "id": "other",
    "header": "Others",
    "drugs": [
      { "id": "miv", "name": "MIV" },
      { "id": "mivf", "name": "MIVF" },
      { "id": "tkoivpb", "name": "TKO + IVPB" },
      { "id": "kvo", "name": "KVO" },
      { "id": "bolus", "name": "Bolus" },
      { "id": "abx", "name": "Antibiotics" },
      { "id": "kcl", "name": "Potassium" },
      { "id": "mg", "name": "Magnesium" },
      { "id": "po4", "name": "Sodium Phos" },
      { "id": "kpo4", "name": "K Phos" },
    ]
  }

];
export default drips;
