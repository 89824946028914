import { StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  page: {

  },
  table: { 
    display: "table", 
    width: '720px',
    height: '360px',
    marginTop: '36px',
    marginLeft:'36px',
    marginRight: '36px',
    marginBottom: '288px',
  }, 
  tableRow: { 
    margin: 0,
    border: 0,
    padding: 0,
    flexDirection: "row" ,
    height: '72px',
    width: '720px'
  }, 
  tableCol: { 
    width: "180px",
    borderStyle: "solid",
    borderWidth: 0,
  }, 
  tableCell: { 
    height: '72px',
    paddingVertical: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 24,
    fontStyle: 'bold',
    display: 'table-cell',
    lineHeight: '100%',
  },

});

export default styles;