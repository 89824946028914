import React, { Component, Fragment } from "react";
import { View, Text, Document, Page } from '@react-pdf/renderer';
import { drips } from "../../config/drips";
import styles from './style';


class PDFDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drips: drips,
      isShiftDown: props.state.isShiftDown,
      selectedDrips: props.state.selectedDrips,
      lastSelectedDrip: props.state.lastSelectedDrip,
      fullPage: props.state.fullPage,
      perDrug: props.state.perDrug,
    };
  }

  render() {
    let { pages } = this.props;
    return (
      <>
        <Document title="Drug Labels" >
          {this.renderDocument(pages)}
        </Document>
      </>
    );
  }

  renderDocument(pages) {
    return (
      <>
        {pages.map(
          (page, key) => (
            <Page size="letter" orientation="landscape" key={key} style={styles.page} wrap={true} debug={false}>
              <Table page={page} />
            </Page>
          )
        )}
      </>
    )
  }
}
export default PDFDocument;

const Table = ({ page }) => {
  return (
    <View style={styles.table} debug={false}>
      <TableRows items={page} />
    </View>
  );
};

const TableRows = ({ items }) => {
  const rows = items.map((item, iid) =>
    <View style={styles.tableRow} key={`tr-${iid}`} debug={false}>
      <TableCells cols={item.cols} />
    </View>
  )
  return (<Fragment>{rows}</Fragment>)
};

const TableCells = ({ cols }) => {
  const result = cols.map((cell, index) => (
    <TableCell key={index} cell={cell} />
  ))
  return (<Fragment>{result}</Fragment>)
};

const TableCell = ({ cell }) => {
  return (
    <View style={styles.tableCol} key={`f-${cell.fid}`} debug={false}>
      <Text style={styles.tableCell} key={cell.fid} debug={false}>{cell.name}</Text>
    </View>
  )
};