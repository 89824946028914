import React, { Component } from "react";
import { BlobProvider } from '@react-pdf/renderer';
import { drips } from "../../config/drips";
import  PDFDocument from "../PDFDocument/index";


const tableRowsCount = 5;
const tableColsCount = 4;
const tableFieldCount = tableColsCount * tableRowsCount;


class Printout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drips: drips,
      isShiftDown: props.state.isShiftDown,
      selectedDrips: props.state.selectedDrips,
      lastSelectedDrip: props.state.lastSelectedDrip,
      fullPage: props.state.fullPage,
      perDrug: props.state.perDrug,
    };
  }

  render() {
    const { selectedDrips, fullPage, perDrug } = this.props.state;
    let numDrips = selectedDrips.length;
    let numFields = numDrips * perDrug;
    let numPages = 1;
    let labels = this.generateLabels(this.props.state);
    let pages = [];
    if (fullPage === true) {
      // A full page per drip
      numPages = numDrips;
      for (let p = 0; p < numPages; p++) {
        let begin = p * tableFieldCount;
        let end = (p + 1) * tableFieldCount;
        let page = this.pageFromLabels(p, labels.slice(begin,end));
        pages[p] = page;
      } 
    } else {
      // Pages filled based on perDrug
      numPages = numFields / tableFieldCount;
      for (let p = 0; p < numPages; p++) {
        let begin = p * tableFieldCount;
        let end = (p + 1) * tableFieldCount;
        let page = this.pageFromLabels(p, labels.slice(begin,end));

        pages[p] = page;

      }
    }


    return (
      <>
            <BlobProvider document={ <PDFDocument state={this.props.state} pages={pages} /> }>
            {
              ({ url, blob, loading, error }) => {
                if (loading) {
                  return (
                    <span>
                      generating document...
                    </span>
                  );
                }

                if (!loading && url) {
                  return <iframe title="pdf-drug-labels" src={url} style={{ width: "100%", height: "600px" }} />
                }
                if (error) {
                  console.error(error);
                  return <p>An error occurred</p>;
                }
              }
            }
            </BlobProvider>
            
      </>
    )
 
  }

  generateLabels(props) {
    const { selectedDrips, fullPage, perDrug } = props;
    let numDrips = selectedDrips.length;
    let numFields = numDrips * perDrug;

    let labels = [];
    let tc = 0;
    if (numDrips > 0) {
      if (fullPage === true) {
        for (let i = 0; i < numDrips; i++) {
          for (let j = 0; j < tableFieldCount; j++) {
            let field = {
              fid: tc,
              name: selectedDrips[i]
            };
            labels.push(field);
            tc++;
          }
        }
      } else {
        let tlc = [];
        let numPages = numFields / tableFieldCount;
        if (numFields < tableFieldCount) {
          let numBlank = tableFieldCount - numFields;
          let blankFields = this.fillBlankSpace(numBlank);
          for (let i = 0; i < numDrips; i++) { 
            for (let j = 0; j < perDrug; j++) {
              let field = {
                  fid: tc,
                  name: selectedDrips[i]
              };
              tlc.push(field);
              tc++;
            }
          }
          labels = [].concat(tlc, blankFields);
        } else if ((numFields > tableFieldCount) && (numPages > 1)) {
          let lbc = [];
          for (let i = 0; i < numDrips; i++) { 
            for (let j = 0; j < perDrug; j++) {
                let field = {
                    fid: tc,
                    name: selectedDrips[i]
                };
                lbc.push(field);
                tc++;
            }
          }
                   
          for (let p = 0; p < numPages; p++) {
            let begin = p * tableFieldCount;
            let end = (p + 1) * tableFieldCount;
            let lbcPage = lbc.slice(begin,end);
            let lbcNum = lbcPage.length;
            if (lbcNum < tableFieldCount) {
              let numBlank = tableFieldCount - lbcNum;
              let blankFields = this.fillBlankSpace(numBlank);
              labels = [].concat(lbc, blankFields);
            } else {
              labels = lbc;
            }
            //console.log(lbcPage);
          }
        } else {
          for (let i = 0; i < numDrips; i++) { 
              for (let j = 0; j < perDrug; j++) {
                  let field = {
                      fid: tc,
                      name: selectedDrips[i]
                  };
                  labels.push(field);
                  tc++;
              }
          }
        }
      }
    }
    //console.log(labels);
    return labels;
  }

  pageFromLabels(p, labels) {
    let rows = [];
    let li = 0;
    for (let i = 0; i < tableRowsCount; i++) {
      let cols = [];
      for (let j = 0; j < tableColsCount; j++) {
        if (labels[i] !== undefined) {
          cols.push(labels[li]);
          li++;
        } else {
          let field = {
            fid: 'b' + j,
            name: ""
          };
          cols.push(field)
          li++;
        }
      }
      rows.push({cols});
    }
    return rows
  }

  fillBlankSpace(fieldCount) {
    const blankFields = Array(fieldCount).fill(0);
    
    const rows = blankFields.map( (x, i) => {
      let field = {
        fid: x + '-' + i,
        name: " "
      }
      return field;

    })
    return rows;
  }
}

export default Printout;