import React, { Component } from "react";
import Printout from "../Printout/index";
import { drips } from "../../config/drips";
//import {PDFViewer} from '@react-pdf/renderer';
//import Sheet from "../Sheet/index";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drips: drips,
      isShiftDown: false,
      selectedDrips: [],
      lastSelectedDrip: null,
      fullPage: false,
      perDrug: 4,
    };
    this.listEl = null;

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectStart = this.handleSelectStart.bind(this);
    this.handlePerDrug = this.handlePerDrug.bind(this);
    this.handleFullPage = this.handleFullPage.bind(this);
  }

     
  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp, false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    this.listEl.addEventListener("selectstart", this.handleSelectStart, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    this.listEl.removeEventListener("selectstart", this.handleSelectStart);
  }

  handleSelectStart(e) {
    // if we're clicking the labels it'll select the text if holding shift
    if (this.state.isShiftDown) {
      e.preventDefault();
    }
  }

  handleKeyUp(e) {
    if (e.key === "Shift" && this.state.isShiftDown) {
      this.setState({ isShiftDown: false });
    }
  }

  handleKeyDown(e) {
    if (e.key === "Shift" && !this.state.isShiftDown) {
      this.setState({ isShiftDown: true });
    }
  }

  handlePerDrug(e) {
    const {value} = e.target;
    //this.setState({ perDrug: value }, () => { console.log(this.state) });
    this.setState({ perDrug: value });
  }

  handleFullPage(e) {
    //this.setState({ fullPage: !this.state.fullPage }, () => { console.log(this.state) });
    this.setState({ fullPage: !this.state.fullPage });
  }

  
  handleSelectItem(e) {
    const { value } = e.target;
    const nextValue = this.getNextValue(value);

    this.setState({ selectedDrips: nextValue, lastSelectedDrip: value });
    //this.setState({ selectedDrips: nextValue, lastSelectedDrip: value }, () => { console.log(this.state) });
  }

  getNextValue(value) {
    const { isShiftDown, selectedDrips } = this.state;
    const hasBeenSelected = !selectedDrips.includes(value);

    if (isShiftDown) {
      const newselectedDrips = this.getNewselectedDrips(value);
      // de-dupe the array using a Set
      const selections = [...new Set([...selectedDrips, ...newselectedDrips])];

      if (!hasBeenSelected) {
        return selections.filter(item => !newselectedDrips.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selectedDrips.includes(value)
      ? selectedDrips.filter(item => item !== value)
      : [...selectedDrips, value];
  }

  getNewselectedDrips(value) {
    const { lastSelectedDrip, drips } = this.state;
    const currentSelectedIndex = drips.findIndex(item => item.id === value);
    const lastSelectedIndex = drips.findIndex(
      item => item.id === lastSelectedDrip
    );

    return drips
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .map(item => item.id);
   }



  render() {
    return (
      <>
        {this.sidebar()}
        {this.content()}
      </>
    )
  }

  sidebar() {
    return (
      <>
      <nav className="sidebar" id="sidebar">
        <a className="sidebar-brand" href="index.html"><svg>
        <use xlinkHref="#ion-ios-pulse-strong"></use></svg> Nursing Tools</a>
        <div className="sidebar-content">
          <div className="sidebar-user">
            <div className="font-weight-bold">
              Drip Label Maker
            </div><small>for the ICU</small>
          </div>
          <ul className="sidebar-nav" ref={node => (this.listEl = node)}>
            <li className="sidebar-header">Main</li>
            {drips.map((section, index) => this.renderSection(section, index))}
            </ul>
        </div>
      </nav>
      </>
    );
  }

  renderSection(section, index) {
    return (
      <li className="sidebar-item" key={index}>
          <a href={"#" + section.id} data-toggle="collapse" className="sidebar-link collapsed">
          <span className="align-middle">{section.header}</span>
        </a>
        <ul id={section.id} className="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
          {section.drugs.map((gtt) => this.renderDrips(gtt, section))}

        </ul>
      </li>
    );
  }

  renderDrips(item, section) {
    const { selectedDrips } = this.state;
    return (
      <li className="sidebar-item" key={item.id.toString()}>
        <label className="custom-control custom-checkbox">
          <input 
            type="checkbox" 
            className="custom-control-input" 
            name={item.id} 
            value={item.name}
            onChange={this.handleSelectItem}
            checked={selectedDrips.includes(item.name)}
            id={`gtt-${item.id}`}
            />
          <span className="custom-control-label">{item.name}</span>
        </label>
    </li>
    )
  }



  content() {
    const { selectedDrips } = this.state;
     return (
          <>
            <div className="main">
                {this.navbar()}
                <main className="content">
                    <div className="container-fluid">
                      <div className="header">
                          <h1 className="header-title">Drip Label Maker</h1>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                      <h5 className="card-title mb-0">{selectedDrips.length} Drips Selected.</h5>
                                    </div>
                                    <div className="card-body">
                                          <Printout state={this.state} />
                                    </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </main>
                <footer className="footer">

                </footer>
              </div>
          </>
     );
   }

   printSelectedDrips(selectedDrips) {
     return (
        <ul>
        {selectedDrips.map((drip, d) => {
          return (
          <li key={d}>
          {drip}
          </li>
          );
        })
        }
      </ul>
     );
   }

   navbar() {
    //const { perDrug, fullPage } = this.state;
     return (
       <>
        <nav className="navbar navbar-expand navbar-theme">
          <div className="container-fluid justify-content-start">
             
              <a href="#" className="sidebar-toggle d-flex mr-2" >
                <i className="hamburger align-self-center"></i>
              </a>
              <form className="d-inline-flex flex-row align-middle" id="printoptions">
                <div className="p-2">
                  <select className="custom-select" id="perdrug" name="perdrug" value={this.state.perDrug} onChange={this.handlePerDrug}>
                    <option value="0">Labels per Drug</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
                <div className="p-2">
                  <label className="custom-control custom-checkbox" htmlFor="fullpage">
                    <input type="checkbox" className="custom-control-input"  name="fullpage"  id="fullpage" defaultChecked={this.state.fullPage} value="1"  onChange={this.handleFullPage} />
                    <span className="custom-control-label form-control-lite">Full Page per drug?</span>
                  </label>
                </div>
                <div className="p-2">
                </div>
              </form>
          </div>
        </nav>
       </>
     )
   }

  

}

export default Content;